import {get, post, put} from '~/api/index';
import {alerts} from '~/composables/alerts';
import appConfig from '~/app.config';
import handleException from '~/services/exceptions';
import {useUserStore} from '~/store/user';
import {ApiReturn, ApiReturnCode} from '~/@types/clouAuth';
import access from '~/composables/access';
import {showError} from '#app';
import {useAuthUserStore} from '~/store/authUser';
import {User, UserBasic} from '~/@types/user';

export default class UserApi {
  public static resourceName = '/users';

  /**
   * List users
   */
  static async list (): Promise<void> {
    try {
      // Check access
      if (!access.verify('USERS-VIEW')) {
        alerts().show(appConfig.accessError('view users'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
        return;
      }

      const userStore = useUserStore();
      const response = await get(`${this.resourceName}`, userStore.listParams);

      if (response.data && response.data.data && response.data.meta) {
        userStore.setListData({
          users: response.data.data,
          meta: response.data.meta,
        });
        return;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get users'), 'error');
      return;
    }
  }

  /**
   * Create a user
   *
   * @userData
   */
  static async create (userData: {firstName: string; lastName: string, email: string; roleSlug: string, companyDid?: string | null}): Promise<ApiReturnCode | string> {
    try {
      // Check access
      if (!access.verify('USERS-MODIFY')) {
        alerts().show(appConfig.accessError('create users'), 'warning');
        return 401;
      }

      const response = await post(`${this.resourceName}`, userData);

      if (response.code === 200) {
        alerts().show('User added. An invitation has been sent to the user.');
        return response.data;
      }

      if ([200, 406].includes(response.code)) {
        return response.code;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('create a user'), 'error');
      return 500;
    }
  }

  /**
   * Show a user
   *
   * @param did
   */
  static async show (did: string): Promise<ApiReturn> {
    try {
      const authUserStore = useAuthUserStore();
      const interactingWithOwnAccountData = did === (authUserStore.user as User).did;
      // Check access
      if (!access.verify('USERS-VIEW') && !interactingWithOwnAccountData) {
        alerts().show(appConfig.accessError('view users'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
        return {data: null, code: 401};
      }

      const response = await get(`${this.resourceName}/${did}`);
      if ([200, 404].includes(response.code)) {
        return response;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get user data'), 'error');
      return {data: null, code: 500};
    }
  }

  /**
   * Update users
   *
   * @param dids
   * @param data
   */
  static async update (dids: string[], data: any): Promise<void> {
    try {
      const authUserStore = useAuthUserStore();
      const isUpdatingOwnDetails = dids.length === 1 && dids[0] === (authUserStore.user as User).did;
      // Check access
      if (!access.verify('USERS-MODIFY') && !isUpdatingOwnDetails) {
        alerts().show(appConfig.accessError('modify users'), 'warning');
        return;
      }

      const response = await put(`${this.resourceName}`, {dids, data});

      if (response.code === 200) {
        return;
      }
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('update the user'), 'error');
      return;
    }
  }

  /**
   * Get user's login history
   *
   * @param did
   * @param perPage
   */
  static async getLoginHistory (did: string, perPage = 6): Promise<ApiReturn> {
    try {
      const authUserStore = useAuthUserStore();
      const interactingWithOwnAccountData = did === (authUserStore.user as User).did;
      // Check access
      if (!access.verify('USERS-VIEW') && !interactingWithOwnAccountData) {
        alerts().show(appConfig.accessError('view users'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
        return {data: null, code: 401};
      }

      const response = await get(`${this.resourceName}/get-login-history/${did}`, {perPage});
      if (response.code === 200) {
        return response;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get user login history'), 'error');
      return {data: null, code: 500};
    }
  }

  /**
   * Change user password
   *
   * @param did
   * @param data
   */
  static async changePassword (did: string, data: {currentPassword: string; newPassword: string}): Promise<ApiReturnCode> {
    try {
      const response = await put(`${this.resourceName}/change-password/${did}`, data);
      if (response.code === 200) {
        alerts().show('Password changed!');
        return response.code;
      } else if (response.code === 401) {
        alerts().show('Sorry. Entered current password is wrong!', 'error');
        return response.code;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('change the password'), 'error');
      return 500;
    }
  }

  /**
   * A basic user list
   *
   * @param searchKeyword
   * @param page
   * @param itemsPerPage
   * @param companyDid
   * @param roleIds
   */
  static async basicList (searchKeyword = '', page = 1, itemsPerPage = 20, companyDid: string | null = null, roleIds: number[] = []): Promise<UserBasic[]> {
    try {
      // Check access
      if (!access.verify('USERS-VIEW')) {
        alerts().show(appConfig.accessError('view users'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
      }

      const companyDidFilter: any[] = [];
      if (companyDid) {
        companyDidFilter.push({operation: 'where', field: 'companyDid', value: companyDid});
      }

      if (roleIds.length) {
        companyDidFilter.push({operation: 'whereIn', field: 'roleId', value: roleIds});
      }

      const companies = await get(`${this.resourceName}`, {
        page,
        sortBy: '[]',
        isBasic: true,
        itemsPerPage,
        filters: JSON.stringify([{operation: 'search', field: 'name', value: searchKeyword}, ...companyDidFilter]),
      });

      if (companies.code === 200) {
        return companies.data.data;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get users'), 'error');
      return [];
    }
  }

  /**
   * Create an agent profile
   */
  static async createAgentProfile (userDid: string, officeDid: string): Promise<ApiReturn> {
    try {
      const response = await post(`${this.resourceName}/create-agent-profile`, {userDid, officeDid});

      if (response.code === 200) {
        alerts().show('Agent profile created.');
        return response;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('create an agent profile'), 'error');
      return {data: null, code: 500};
    }
  }
}
