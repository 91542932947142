import { defineStore } from 'pinia';
import {User} from '~/@types/user';
import {DataTableOptions, PaginationMeta} from '~/@types/common';

export interface UserListData {
  users: User[];
  meta: PaginationMeta | null;
}

export interface UserState {
  listParams: DataTableOptions;
  userListData: UserListData;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    listParams: {
      page: 1,
      sortBy: '[]',
      pageCount: 0,
      itemsPerPage: 10,
      filters: [],
    },
    userListData: {
      users: [],
      meta: null,
    },
  }),
  persist: {
    paths: ['listParams'],
    storage: persistedState.cookiesWithOptions({
      maxAge: 3600 * 1000 * 24 * 365,
    }),
  },
  actions: {
    setListParams (listParams: DataTableOptions) {
      this.listParams = listParams;
    },
    setListData (userListData: UserListData) {
      this.userListData = userListData;
    },
  },
});
